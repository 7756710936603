@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  color: rgb(80, 80, 80);
  scrollbar-color: red;
}

p {
  margin-bottom: 15px;
}
